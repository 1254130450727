import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ZendeskComponent } from './zendesk.component';
import { HomeZendeskComponent } from './home/home-zendesk.component';
import { ActivityZendeskComponent } from './activity/activity-zendesk.component';
import { CreateZendeskComponent } from './create/create-zendesk.component';
import { SearchInformationZendeskComponent } from './search-information/search-information-zendesk.component';
import { LoggerService } from './logger.service';
import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';

@NgModule({
  declarations: [
    ZendeskComponent,
    HomeZendeskComponent,
    ActivityZendeskComponent,
    CreateZendeskComponent,
    SearchInformationZendeskComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeZendeskComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigurationService,
        loggerService: LoggerService
      ) => async () => {
        await configService.loadConfigurationData();
        await loggerService.initialize();
      },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
    LoggerService,
    StorageService
  ],
  bootstrap: [ZendeskComponent]
})
export class AppModule { }
