import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';
@Component({
  selector: 'app-search-information',
  templateUrl: './search-information-zendesk.component.html',
  styleUrls: ['./search-information-zendesk.component.css']
})
export class SearchInformationZendeskComponent implements OnChanges {
  @Input() scenarioId: string;
  @Input() searchRecordList: Array<api.IRecordItem>;
  @Input() searchLayout: object;
  @Output() agentSelectedCallerInformation: EventEmitter<any> = new EventEmitter();
  protected dropped: boolean;
  protected isSearchInformationMaximized: boolean;
  protected entityDisplayInfo: Object;
  protected entityImages: string[];
  protected entityNames: string[];
  protected multiMatchNames: string[];
  protected searchElementList: string[];
  protected properSearchElementList: string[];

  constructor(private loggerService: LoggerService, protected storageService: StorageService) {
    this.loggerService.logger.logDebug(`searchInformationComponent: Constructor start`);
    this.isSearchInformationMaximized = true;
    this.dropped = false;
    this.loggerService.logger.logDebug(`searchInformationComponent: Constructor complete`);
  }

  ngOnChanges() {
    this.renderData();
  }

  protected renderData() {
    this.properSearchElementList = [];
    this.entityNames = this.parseSearchRecordsForNames(this.searchRecordList);
    this.entityImages = this.parseImgToDisplay(this.searchRecordList);
    if (this.searchRecordList.length === 1) {
      this.entityDisplayInfo = this.parseEntityInfo(this.searchRecordList[0]);
    } else {
      this.entityDisplayInfo = {};
    }

    for (let i = 0; i < this.properSearchElementList.length; i++) {
      let element = this.properSearchElementList[i];
      let fl = element.slice(0, 1);
      const end = element.slice(1);
      fl = fl.toUpperCase();
      element = fl + end;
      this.properSearchElementList[i] = element;
    }
  }

  private parseEntityInfo(searchRecord): Object {
    const entityList = {};
    this.searchElementList = [];

    const entityKeys = Object.keys(this.searchLayout);
    for (let index = 1; index < entityKeys.length; index++) {
      const element = entityKeys[index];

      const apiName = this.searchLayout[element].variables.APIName;
      if (apiName === searchRecord.type) {
        this.searchElementList = this.searchLayout[element].variables.DefaultSearchFields;
      }
    }

    const keys = Object.keys(searchRecord.fields);
    this.properSearchElementList = [];
    for (let i = 0; i < keys.length; i++) {

      this.searchElementList.forEach(element => {
        if (element === keys[i].toLowerCase() && searchRecord.fields[keys[i]].Value) {
          entityList[keys[i].toLowerCase()] = searchRecord.fields[keys[i]].Value;
          this.properSearchElementList.push(element);
        }
      });
    }
    this.searchElementList = this.properSearchElementList.slice();

    return entityList;
  }

  protected onAgentSelectedCallerInformation(event) {
    try {
      if (event.currentTarget.id !== '') {
        this.loggerService.logger.logDebug(`searchInformationComponent: Agent selected caller info: ${event.currentTarget.value}`,
          api.ERROR_CODE.SEARCH_RECORD);
        const searchRecord = this.searchRecordList.find(i => Number(i.id) === Number(event.currentTarget.id));
        this.agentSelectedCallerInformation.emit(searchRecord);
      }
    } catch (e) {
      this.loggerService.logger.logError(`searchInformationComponent: Agent selected caller info: ${e}`,
        api.ERROR_CODE.SEARCH_RECORD);
    }
  }

  protected parseImgToDisplay(searchRecordList): string[] {
    const arrImages: string[] = [];

    let index = 0;
    searchRecordList.forEach(element => {
      if (element.type === 'end-user') {
        arrImages[index] = 'assets/images/Miscellaneous_Icon.png';
      }

      index++;
    });

    return arrImages;
  }

  protected parseSearchRecordsForNames(searchRecordList): string[] {
    const arr = [];

    let index = 0;
    searchRecordList.forEach(element => {
      const keys = Object.keys(element.fields);
      let nameKey;
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].includes('Name')) {
          nameKey = keys[i];
          break;
        }
      }
      arr[index] = element.fields[nameKey].Value;

      index++;
    });

    this.multiMatchNames = arr.slice(1);
    return arr;
  }

  dropMultiMatchOptions() {
    this.dropped = !this.dropped;
  }
}
