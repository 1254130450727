import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoggerService } from '../logger.service';
@Component({
  selector: 'app-create',
  templateUrl: './create-zendesk.component.html',
  styleUrls: ['./create-zendesk.component.css']
})
export class CreateZendeskComponent implements OnInit {
  @Output() CreateNewEntity: EventEmitter<string> = new EventEmitter<string>();
  isMaximizeCreate: boolean;
  constructor(private loggerService: LoggerService) {
    this.isMaximizeCreate = true;
  }
  ngOnInit() {
  }
  protected createNewEntity(type) {
    this.CreateNewEntity.emit(type);
  }
}
