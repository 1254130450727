import { Component, OnInit } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { Application } from '@amc-technology/applicationangularframework';
import { bind } from 'bind-decorator';
import { Subject } from 'rxjs';
import { ITicket } from '../Model/ITicket';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';
import { IInteraction } from '@amc-technology/davinci-api';
import { tick } from '@angular/core/testing';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-home',
  templateUrl: './home-zendesk.component.html'
})
export class HomeZendeskComponent extends Application implements OnInit {
  protected interactionDisconnected: Subject<boolean> = new Subject();
  protected autoSave: Subject<void> = new Subject();
  protected phoneNumberFormat: Object;
  public clickToDialPhoneReformatMap: Object;
  protected quickCommentList: string[];
  protected searchLayout;
  protected focusedActivity;
  protected isConfiguredForBranding: boolean;
  protected brandingMap: any;

  constructor(private loggerService: LoggerService, protected storageService: StorageService) {
    super(loggerService.logger);
    this.isConfiguredForBranding = false;
    this.phoneNumberFormat = {};
    this.clickToDialPhoneReformatMap = {};
    this.loggerService.logger.logDebug('ZendeskHomeComponent: constructor start');
    this.storageService.syncWithLocalStorage();
  }

  async ngOnInit() {
    await this.loadConfig();
    this.bridgeScripts = this.bridgeScripts.concat([
      this.getBridgeURL(),
      window.location.origin + '/zaf_sdk.js'
    ]);
    await super.ngOnInit();

    const configPhoneFormat = this.appConfig.variables['PhoneNumberFormat'];
    if (typeof configPhoneFormat === 'string') {
      const tempFormat = String(configPhoneFormat).toLowerCase();
      this.phoneNumberFormat[tempFormat] = tempFormat;
    } else {
      this.phoneNumberFormat = configPhoneFormat;
    }

    if (this.appConfig.variables['ClickToDialPhoneReformatMap']) {
      this.clickToDialPhoneReformatMap = this.appConfig.variables['ClickToDialPhoneReformatMap'];
    }

    this.bridgeEventsService.subscribe('clickToDial', event => {
      const reformattedNumber =  this.clickToDialFormatPhoneNumber(event.number);
      api.clickToDial(reformattedNumber);
    });
    this.searchLayout = this.appConfig.SearchLayout.Entities;
    if (this.appConfig.variables.hasOwnProperty('BrandingMap') && (Object.keys(this.appConfig.variables['BrandingMap']).length > 0)) {
      this.isConfiguredForBranding = true;
      this.brandingMap = this.appConfig.variables['BrandingMap'];
      this.logger.logDebug('Zendesk - Home: sending branding map to bridge: ' + JSON.stringify(this.brandingMap));
      await this.bridgeEventsService.sendEvent('getBrandingMap', this.brandingMap);
    }
    this.quickCommentList = <string[]>this.appConfig.variables['QuickComments'];
    api.registerOnLogout(this.removeLocalStorageOnLogout.bind(this));
    this.loggerService.logger.logDebug('AMCZendeskHomeComponent: ngOnInit complete');
  }

  protected removeLocalStorageOnLogout(reason?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.logger.pushLogsAsync()
      localStorage.clear();
    });
  }

  protected formatPhoneNumber(inputNumber: string, phoneNumberFormat: Object): string {
    try {
      this.logger.logTrace('Zendesk - Home : START : Formatting Phone Number. Input Number : ' + inputNumber +
        '. Configured Format : ' + JSON.stringify(phoneNumberFormat));
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (inputFormat[j] !== '?' && inputNumber[j] !== inputFormat[j]) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber = outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logTrace('Zendesk - Home : END : Formatting Phone Number. Input Number : ' + inputNumber +
              '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Output Number : ' + outputNumber);
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.logger.logError('Zendesk - Home : ERROR : Formatting Phone Number. Input Number : ' + inputNumber +
        '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Error Information : ' + JSON.stringify(error));
    }
    this.logger.logTrace('Zendesk - Home : END : Formatting Phone Number. Input Number : ' + inputNumber +
      '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Output Number : ' + inputNumber);
    return inputNumber;
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(this.clickToDialPhoneReformatMap);
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat = this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
          continue;
        }
        if (((inputFormat.match(/\(/g) || []).length) !== ((number.match(/\(/g) || []).length)) {
          continue;
        }
        if (((inputFormat.match(/-/g) || []).length) !== ((number.match(/-/g) || []).length)) {
          continue;
        }

        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber = outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  protected reverse(input: string): string {
    let reverse = '';
    for (let i = 0; i < input.length; i++) {
      reverse = input[i] + reverse;
    }
    return reverse;
  }

  protected formatCrmResults(crmResults: any, scenarioId?: string): api.SearchRecords {
    const result = new api.SearchRecords();
    for (let index = 0; index < crmResults.length; index++) {
      let recordItem: api.RecordItem = null;
      if (crmResults[index].id && crmResults[index].role) {
        recordItem = new api.RecordItem(
          crmResults[index].id,
          crmResults[index].role,
          crmResults[index].name
        );
      }
      if (recordItem !== null) {
        if (crmResults[index].name) {
          if (recordItem.getMetadata().Type === 'end-user') {
            recordItem.setFullName('Name', 'Name', crmResults[index].name);
          }
          if (crmResults[index].phone) {
            recordItem.setField(
              'phone',
              'phone',
              'phone',
              crmResults[index].phone
            );
          }
          if (crmResults[index].phone) {
            recordItem.setField(
              'email',
              'email',
              'email',
              crmResults[index].email
            );
          }
        }
        result.addSearchRecord(recordItem);
      }
    }
    if (crmResults.length === 1) {
      const ticketId = crmResults[0].ticketId;
      this.storageService.setRecordTicketMap(crmResults[0].id + scenarioId, ticketId);
      this.storageService.setScenarioTicketMap(scenarioId, crmResults[0].ticketId);
      this.storageService.addTicket(this.createTicket(crmResults[0].ticketId, crmResults[0].name, scenarioId));
      this.storageService.setCurrentTicketId(scenarioId, crmResults[0].ticketId);
    }
    return result;
  }

  protected parseSearchLayoutEntities(salesforceLayout: any): api.ISearchLayoutForEntity[] {
    const layoutsForEntities: api.ISearchLayoutForEntity[] = [];
    for (const entityName of Object.keys(salesforceLayout.objects)) {
      const layoutForEntity: api.ISearchLayoutForEntity = {
        DisplayName: entityName,
        DevName: entityName,
        DisplayFields: [],
        PhoneFields: [],
        EmailFields: [],
        SocialFields: [],
        NameFields: []
      };
      for (const field of Object.values<{ apiName; displayName }>(
        salesforceLayout.objects[entityName]
      )) {
        layoutForEntity.DisplayFields.push({
          DevName: field.apiName,
          DisplayName: field.displayName,
          Value: null
        });
      }
      layoutsForEntities.push(layoutForEntity);
    }
    return layoutsForEntities;
  }

  protected saveActivity(activity): Promise<string> {
    return new Promise((resolve, reject) => {
      this.bridgeEventsService.sendEvent('saveActivity', activity);
      resolve('not implemented');
    });
  }

  protected formatDate(date: Date): string {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = '' + date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  }

  @bind
  protected saveActivityResponse(activity: any) { }

  protected async processIfNewScenario(interaction: api.IInteraction): Promise<boolean> {
    try {
      this.logger.logTrace('Zendesk - Home : START : Checking if the interaction is new or existing. Interaction Info : '
        + JSON.stringify(interaction));
      if (!this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId)) {
        this.scenarioInteractionMappings[interaction.scenarioId] = {};
        this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId] = true;
        this.storageService.setActiveScenarioId(interaction.scenarioId);
        this.logger.logInformation('Zendesk - Home : New Scenario with Scenario ID : ' + interaction.scenarioId);
        this.logger.logTrace('Zendesk - Home : END : Checking if the interaction is new or existing. Interaction Info : '
          + JSON.stringify(interaction));
        return true;
      }
    } catch (error) {
      this.logger.logError('Zendesk - Home : ERROR : Checking if the interaction is new or existing. Interaction Info : '
        + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
    this.logger.logTrace('Zendesk - Home : END : Checking if the interaction is new or existing. Interaction Info : '
      + JSON.stringify(interaction));
    return false;
  }

  protected async onInteraction(interaction: api.IInteraction): Promise<api.SearchRecords> {
    try {
      const scenarioId = interaction.scenarioId;
      let isNewScenarioId = false;
      if (interaction.channelType === api.CHANNEL_TYPES.Telephony || interaction.channelType === api.CHANNEL_TYPES.SMS) {
        interaction.details.fields.Phone.Value = this.formatPhoneNumber(interaction.details.fields.Phone.Value, this.phoneNumberFormat);
      }
      isNewScenarioId = await this.processIfNewScenario(interaction);
      if (interaction['userFocus'] || (this.storageService.activeScenarioIdList.length === 1 &&
        this.storageService.activeScenarioIdList.indexOf(scenarioId) >= 0)) {
        this.storageService.setCurrentScenarioId(scenarioId);
      }
      if (interaction.state !== api.INTERACTION_STATES.Disconnected) {
        this.storageService.addInteraction(interaction);
        let searchRecord = null;
        if (!this.storageService.searchRecordList[scenarioId]) {
          searchRecord = await this.searchAndScreenpop(interaction, isNewScenarioId);
          this.storageService.setsearchRecordList(searchRecord.toJSON(), scenarioId);
        }
        return searchRecord;
      } else if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        this.deleteExistingScenario(interaction);
      }
    } catch (e) {
      const msg = `Error in onInteraction! Exception details: ${e.message}`;
      this.loggerService.logger.logError(msg, api.ERROR_CODE.INTERACTION_EVENT);
      throw msg;
    }
    return;
  }

  protected async preformScreenpop(interaction: api.IInteraction, isSearch?: boolean) {
    const event = this.generateEventForScreenpop(interaction);
    event['isSearch'] = isSearch;
    const dnis = this.retrieveCurrentDNIS(this.storageService.currentScenarioId);
    event['dnis'] = dnis;
    const screenpopResult = await this.bridgeEventsService.sendEvent(isSearch ? 'search' : 'screenPop', event);
    const records = this.formatCrmResults(screenpopResult, interaction.scenarioId);
    this.logger.logDebug('preformScreenpop records=' + screenpopResult);
    this.logger.logDebug('preformScreenpop END');
    return records;
  }

  protected deleteExistingScenario(interaction: api.IInteraction): void {
    try {
      this.logger.logInformation('Zendesk - Home : START : Removing Scenario ID : ' + interaction.scenarioId);
      if (this.scenarioInteractionMappings[interaction.scenarioId]) {
        delete this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId];
        if (Object.keys(this.scenarioInteractionMappings[interaction.scenarioId]).length === 0) {
          this.updateScenarioTickets(interaction.scenarioId);
          this.storageService.onInteractionDisconnect(interaction.scenarioId);
          delete this.scenarioInteractionMappings[interaction.scenarioId];
        }
      }
      this.logger.logInformation('Zendesk - Home : END : Removing Scenario ID : ' + interaction.scenarioId);
    } catch (error) {
      this.logger.logError('Zendesk - Home : ERROR : Deleting existing Scenario. Scenario ID : ' + interaction.scenarioId +
      '. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected updateScenarioTickets(scenarioId: string) {
    if (!this.storageService.scenarioTicketMap) {
      return;
    }
    const tickets = this.storageService.scenarioTicketMap[scenarioId];
    if (!tickets) {
      return;
    }
    for (let index = 0; index < tickets.length; index++) {
      const ticketId = tickets[index];
      this.storageService.ticketList[ticketId].IsDisconnected = true;
      this.saveTicket(tickets[index]);
    }
  }

  private async searchAndScreenpop(interaction: api.IInteraction, isNewScenarioId: boolean) {
    try {
        this.logger.logInformation('Zendesk - Home : Screen pop on interaction. Scenario ID : ' + interaction.scenarioId);
        this.logger.logDebug('Zendesk - Home : Screen pop on interaction. Interaction Info : ' + JSON.stringify(interaction));
        const records = await this.preformScreenpop(interaction, !this.shouldPreformScreenpop(interaction, isNewScenarioId));
        this.logger.logDebug('Zendesk - Home : Screen pop on interaction. Results : ' + JSON.stringify(records));
        return records;
    } catch (error) {
      this.logger.logError('Zendesk - Home : ERROR : Search and Screen pop. Interaction Info : ' + JSON.stringify(interaction)
        + '. Error Information : ' + JSON.stringify(error));
    }
  }

  retrieveCurrentDNIS(scenarioId: string) {
    try {
      this.logger.logDebug('Zendesk - Home: Start retrieveCurrentDNIS. scenarioId:'  + scenarioId +
      ', isConfiguredForBranding: ' + this.isConfiguredForBranding);
        let dnis = null;
        const interaction: IInteraction = this.storageService.interactionList[scenarioId];
        if (interaction && interaction.details && interaction.details.fields && interaction.details.fields['DNIS']) {
          dnis = interaction.details.fields.DNIS.Value.replace(/\D/g, '');
        }
        this.logger.logDebug('Zendesk - Home: retrieveCurrentDNIS: Sending DNIS for setting brand: ' + dnis);
        return dnis;
    } catch (error) {
      this.logger.logError('Zendesk - Home: ERROR in retrieveCurrentDNIS: ' + error);
    }
  }

  protected buildSubjectText(interaction: api.IInteraction) {
    this.logger.logTrace('Zendesk - Home : START : Building Subject Text. Interaction Info : ' + JSON.stringify(interaction));
    let subjectText = '';
    try {
      const channelType = api.CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          subjectText = `${channelType}[${fields.Email.Value}]`;
        } else if (fields.Phone) {
          subjectText = `${channelType}[${fields.Phone.Value}]`;
        } else if (fields.FullName) {
          subjectText = `${channelType}[${fields.FullName.Value}]`;
        }
      }
      this.logger.logInformation('Zendesk - Home : Subject text for Scenario ID : ' + interaction.scenarioId + ' is ' + subjectText);
      this.logger.logTrace('Zendesk - Home : END : Building Subject Text. Interaction Info : ' + JSON.stringify(interaction));
    } catch (error) {
      this.logger.logError('Zendesk - Home : ERROR : Creating new activity. Scenario ID : ' + interaction.scenarioId
        + '. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
    return subjectText;
  }

  protected createTicket(ticketId: string, subject: string, scenarioId: string): ITicket {
    const date = new Date();
    const interaction: IInteraction = this.storageService.interactionList[scenarioId];
    const ticket: ITicket = {
      TicketId: ticketId,
      Subject: subject,
      Description: '',
      IsOutbound: (interaction.direction === api.INTERACTION_DIRECTION_TYPES.Outbound ? true : false),
      IsDisconnected: false,
      TimeStamp: date,
      ScenarioId: scenarioId
    };
    this.loggerService.logger.logDebug(`AMCZendeskHomeComponent: Create new ticket: ${JSON.stringify(ticket)}`, api.ERROR_CODE.ACTIVITY);
    return ticket;
  }

  protected async agentSelectedCallerInformation(searchRecord: api.RecordItem) {
    this.loggerService.logger.logDebug('AMCZendeskHomeComponent: Screenpop selected caller information', api.ERROR_CODE.SCREEN_POP);
    const record = { searchRecord: searchRecord };
    const ticketId = this.storageService.recordTicketMap[searchRecord.id + this.storageService.currentScenarioId];
    if (ticketId) {
      record['ticketId'] = ticketId;
    } else {
      record['dnis'] = this.retrieveCurrentDNIS(this.storageService.currentScenarioId);
    }
    const newTicketId = await this.bridgeEventsService.sendEvent('screenpopTicket', record);
    if (ticketId !== newTicketId) {
      this.storageService.setRecordTicketMap(searchRecord.id + this.storageService.currentScenarioId, newTicketId);
      this.storageService.setScenarioTicketMap(this.storageService.currentScenarioId, newTicketId);
      this.storageService.addTicket(this.createTicket(newTicketId, record.searchRecord.displayName, this.storageService.currentScenarioId));
    }
    this.storageService.setCurrentTicketId(this.storageService.currentScenarioId, newTicketId);
  }

  protected async createNewEntity(newRequest) {
    this.loggerService.logger.logDebug(`Create new ${event} requested`);
    const currentInteraction = this.storageService.getCurrentInteraction();
    const params = {
      type: newRequest.type,
      path: newRequest.path,
      value: {}
    };
    if (currentInteraction) {
      if (currentInteraction.details.fields) {
        const fields = currentInteraction.details.fields;
        if (fields.Email) {
          params.value['email'] = fields.Email.Value;
        } else if (fields.Phone) {
          params.value['phone'] = fields.Phone.Value;
        } else if (fields.FullName) {
          params.value['name'] = fields.FullName.Value;
        }
      }
    }
    const userObject = await this.bridgeEventsService.sendEvent('createNewHandler', params);
    await this.bridgeEventsService.sendEvent('screenpopTicket', userObject);
  }

  protected saveTicket(ticketId: string) {
    const ticket = this.storageService.ticketList[ticketId];
    const params = { id: ticket.TicketId};
    params['description'] = ticket.Description;
    params['outbound'] = ticket.IsOutbound;
    params['disconnect'] = ticket.IsDisconnected;
    params['startTime'] = ticket.TimeStamp;
    this.storageService.setDescription('', ticketId);
    this.bridgeEventsService.sendEvent('updateTicket', params);
  }

  protected getUserInfoHandler() {
    return this.bridgeEventsService.sendEvent('getUserInfo');
  }

  protected getSearchLayout(): Promise<api.SearchLayouts> {
    throw new Error('Method not implemented.');
  }

  protected isToolbarVisible(): Promise<boolean> {
    return this.bridgeEventsService.sendEvent('isToolbarVisible');
  }
}
