import { Injectable } from '@angular/core';
import {
  Logger,
  LOG_SOURCE,
  AbstractLogger,
  getDaVinciAgentConfig,
  LOGGER_TYPE,
  ConsoleLogger,
  FileLogger,
  PremiseLogger
} from '@amc-technology/davinci-api';
import { ConfigurationService } from './configuration.service';
import {
  ILoggerConfiguration,
  defaultLoggerConfiguration
} from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

@Injectable()
export class LoggerService {
  private _logger?: Logger;
  constructor(private configService: ConfigurationService) {
  }

  get logger() {
    if (!this._logger) {
      throw new Error('Logger not initialized!');
    }
    return this._logger;
  }

  async initialize() {
    try {
      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await getDaVinciAgentConfig();

      // Perform data validation, revert to defaults if configs are invalid
      // or undefined

      let logLevel = parseInt(
        rawConfig?.variables?.['Log Level']?.toString(),
        10
      );

      logLevel = isNaN(logLevel)
        ? defaultLoggerConfiguration['Log Level']
        : logLevel;

      let maxLength = parseInt(
        rawConfig?.['Console Logger']?.variables?.['Max Length']?.toString(),
        10
      );

      maxLength = isNaN(maxLength)
        ? defaultLoggerConfiguration['Console Logger']['Max Length']
        : maxLength;

      const loggerConfig: ILoggerConfiguration = {
        'Log Level': logLevel,
        'Logger Type':
          rawConfig?.variables?.['Logger Type']?.toString() ||
          defaultLoggerConfiguration['Logger Type'],
        'Premise Logger URL':
          rawConfig?.variables?.['Premise Logger URL']?.toString() ||
          defaultLoggerConfiguration['Premise Logger URL'],
        'Console Logger': {
          'Max Length': maxLength
        }
      };

      this._logger = new Logger(
        LOG_SOURCE.ZendeskApp,
        false,
        this.configService.config.loggerApiUrl
      );

      this.logger.setConfiguration(loggerConfig);
    } catch (err) {
      this._logger = new Logger(
        LOG_SOURCE.ZendeskApp,
        false,
        this.configService.config.loggerApiUrl
      );

      this.logger.logCritical(
        'loggerService.initialize(): Error creating logger!'
      );
    }
  }
}
