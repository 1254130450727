import { Component, AfterViewChecked, OnInit, ElementRef } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
@Component({
  selector: 'app-root',
  templateUrl: './zendesk.component.html',
  styleUrls: ['./zendesk.component.css']
})
export class ZendeskComponent implements AfterViewChecked {
  height: number;
  width: number;
  el: ElementRef;
  constructor(el: ElementRef) {
    this.height = 500;
    this.el = el;
  }
  ngAfterViewChecked(): void {
    this.setSoftphoneSize();
  }
  private setSoftphoneSize(): void {
    const newHeight = this.getHeight();
    if (newHeight !== this.height) {
      this.height = newHeight;
      api.setAppHeight(this.height);
    }
  }
  private getHeight(): number {
    return this.el.nativeElement.children[0].scrollHeight + 2;
  }
}
