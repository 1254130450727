import * as api from '@amc-technology/davinci-api';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { IInteraction } from '@amc-technology/davinci-api';
import { ITicket } from './Model/ITicket';
import { tick } from '@angular/core/testing';
@Injectable()
export class StorageService {
  public currentScenarioId: string;
  public interactionList: {
    [scenarioId: string]: IInteraction;
  };
  public ticketList: {
    [ticketId: string]: ITicket;
  };
  public searchRecordList: {
    [scenarioId: string]: api.IRecordItem[];
  };
  public activeScenarioIdList: string[];
  public currentScenarioTicketMap: {
    [scenarioId: string]: string;
  };
  public scenarioTicketMap: {
    [scenarioId: string]: string[];
  };
  public recordTicketMap: {
    [recordId: string]: string;
  };

  constructor(private loggerService: LoggerService) {
    this.currentScenarioId = null;
    this.interactionList = {};
    this.ticketList = {};
    this.searchRecordList = {};
    this.activeScenarioIdList = [];
    this.currentScenarioTicketMap = {};
    this.scenarioTicketMap = {};
    this.recordTicketMap = {};
  }

  public getCurrentInteraction() {
    try {
      if (this.currentScenarioId && this.interactionList[this.currentScenarioId]) {
        return this.interactionList[this.currentScenarioId];
      }
      return null;
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Get Current Interaction for Scenario ID : '
      + this.currentScenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setRecordTicketMap(recordId: string, ticketId: string) {
    if (!this.recordTicketMap) {
      this.recordTicketMap = {};
    }
    this.recordTicketMap[recordId] = ticketId;
  }

  public assignCurrentScenarioTicketMap(scenarioId: string) {
    let ticketId = this.currentScenarioTicketMap[scenarioId];
    if (!ticketId) {
      const ticketIdList = this.scenarioTicketMap[scenarioId];
      if (ticketIdList && ticketIdList.length > 0) {
        ticketId = ticketIdList[0];
      }
    }
    if (ticketId) {
      this.setCurrentScenarioTicketMap(scenarioId, ticketId);
    }
  }

  public setCurrentScenarioTicketMap(scenarioId: string, ticketId: string) {
    if (!this.currentScenarioTicketMap) {
      this.currentScenarioTicketMap = {};
    }
    this.currentScenarioTicketMap[scenarioId] = ticketId;
  }

  public setScenarioTicketMap(scenarioId: string, ticketId: string) {
    if (!this.scenarioTicketMap) {
      this.scenarioTicketMap = {};
    }
    if (!this.scenarioTicketMap[scenarioId]) {
      this.scenarioTicketMap[scenarioId] = [];
    }
    this.scenarioTicketMap[scenarioId].push(ticketId);
  }

  public setActiveScenarioId(scenarioId: string) {
    try {
      if (!this.activeScenarioIdList) {
        this.activeScenarioIdList = [];
      }
      this.activeScenarioIdList.push(scenarioId);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Set Active Scenario ID for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setCurrentScenarioId(currentScenarioId: string) {
    try {
      this.currentScenarioId = currentScenarioId;
      if (currentScenarioId) {
        this.assignCurrentScenarioTicketMap(currentScenarioId);
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Set Current Scenario ID for Scenario ID : '
      + currentScenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setCurrentTicketId(scenarioId: string, ticketId: string) {
    try {
      if (scenarioId === this.currentScenarioId) {
        this.setCurrentScenarioTicketMap(scenarioId, ticketId);
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Set Current Ticket ID for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public addTicket(ticket: ITicket) {
    try {
      if (!this.ticketList) {
        this.ticketList = {};
      }
      this.ticketList[ticket.TicketId] = ticket;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Add Activity for Scenario ID : '
      + ticket.ScenarioId + ', Activity Info : ' + JSON.stringify(ticket) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public addInteraction(interaction: IInteraction) {
    if (!this.interactionList) {
      this.interactionList = {};
    }
    if (!this.interactionList[interaction.scenarioId]) {
      this.interactionList[interaction.scenarioId] = interaction;
    }
  }

  private removeInteraction(scenarioId: string) {
    if (this.interactionList[scenarioId]) {
      delete this.interactionList[scenarioId];
    }
  }

  public updateTicket(ticket: ITicket) {
    try {
      if (this.ticketList[ticket.TicketId]) {
        this.ticketList[ticket.ScenarioId] = ticket;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Update Activity for Scenario ID : '
      + ticket.ScenarioId + ', Activity Info : ' + JSON.stringify(ticket) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public removeTickets(scenarioId: string) {
    try {
      const tickets = this.scenarioTicketMap[scenarioId];
      for (let i = 0; i < tickets.length; i++) {
        delete this.ticketList[tickets[i]];
      }
      delete this.scenarioTicketMap[scenarioId];
      delete this.currentScenarioTicketMap[scenarioId];
      this.removeRecordTickets(scenarioId);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Remove Ticket(s) for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private removeRecordTickets(scenarioId: string) {
    const recordKeys = Object.keys(this.recordTicketMap);
      for (let i = 0; i < recordKeys.length; i++) {
        if (recordKeys[i].indexOf(scenarioId) >= 0) {
          delete this.recordTicketMap[recordKeys[i]];
        }
      }
  }

  public setDescription(description: string, ticketId: string) {
    try {
      if (this.ticketList[ticketId]) {
        this.ticketList[ticketId].Description = description;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Set Description for Ticket ID : '
      + ticketId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setsearchRecordList(searchRecords: api.IRecordItem[], scenarioId: string) {
    try {
      this.searchRecordList[scenarioId] = searchRecords;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Set Search Record List for Scenario ID : '
      + scenarioId + ', Search Records : ' + JSON.stringify(searchRecords)  + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearSearchRecordList(scenarioId: string) {
    try {
      delete this.searchRecordList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Clear Search Record List for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public onInteractionDisconnect(scenarioId: string) {
    try {
    this.loggerService.logger.logDebug('Zendesk - Storage : Received Interaction Disconnect Event for Scenario ID : ' + scenarioId);
      this.loggerService.logger.logDebug('Zendesk - Storage : Removing Activity for Scenario ID : ' + scenarioId);
      this.removeTickets(scenarioId);
      this.clearSearchRecordList(scenarioId);
      this.activeScenarioIdList = this.activeScenarioIdList.filter(id => id !== scenarioId);
      this.removeInteraction(scenarioId);
      if (this.currentScenarioId === scenarioId) {
        if (this.activeScenarioIdList.length > 0) {
          this.setCurrentScenarioId(this.activeScenarioIdList[0]);
        } else {
          this.setCurrentScenarioId(null);
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : On Interaction Disconnect. Error Information : '
      + JSON.stringify(error));
    }
  }

  private storeToLocalStorage() {
    try {
      const prevScenarioRecord = localStorage.getItem('scenario');
      const scenarioRecord = JSON.stringify({
        currentScenarioId: this.currentScenarioId,
        interactionList: this.interactionList,
        ticketList: this.ticketList,
        searchRecordList: this.searchRecordList,
        activeScenarioIdList: this.activeScenarioIdList,
        currentScenarioTicketMap: this.currentScenarioTicketMap,
        scenarioTicketMap: this.scenarioTicketMap,
        recordTicketMap: this.recordTicketMap
      });
      this.loggerService.logger.logDebug('Zendesk - Storage : Storing to Local Storage. Scenario before update : ' + prevScenarioRecord);
      localStorage.setItem('scenario', scenarioRecord);
      this.loggerService.logger.logDebug('Zendesk - Storage : DONE : Storing to Local Storage. Scenario after update : '
      + scenarioRecord);
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Storing to Local Storage. Error Information : '
      + JSON.stringify(error));
    }
  }

  public syncWithLocalStorage() {
    try {
      const scenarioRecord = localStorage.getItem('scenario');
      this.loggerService.logger.logDebug('Zendesk - Storage : ERROR : Syncing with Local Storage. Scenario information : '
      + scenarioRecord);
      const browserStorage = JSON.parse(scenarioRecord);
      if (browserStorage) {
        this.currentScenarioId = browserStorage.currentScenarioId;
        this.interactionList = browserStorage.interactionList;
        this.ticketList = browserStorage.ticketList;
        this.searchRecordList = browserStorage.searchRecordList;
        this.activeScenarioIdList = browserStorage.activeScenarioIdList;
        this.currentScenarioTicketMap = browserStorage.currentScenarioTicketMap;
        this.scenarioTicketMap = browserStorage.scenarioTicketMap;
        this.recordTicketMap = browserStorage.recordTicketMap;
      }
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Storage : ERROR : Syncing with Local Storage. Error Information : '
      + JSON.stringify(error));
    }
  }
}
