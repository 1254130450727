import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';
@Component({
  selector: 'app-activity',
  templateUrl: './activity-zendesk.component.html',
  styleUrls: ['./activity-zendesk.component.css']
})
export class ActivityZendeskComponent {
  @Input() ticketId: string;
  @Input() quickCommentList: string[];
  @Output() saveTicket: EventEmitter<string> = new EventEmitter<string>();

  isActivityMaximized: boolean;

  constructor(private loggerService: LoggerService, protected storageService: StorageService) {
    this.isActivityMaximized = true;
  }

  protected submitTicket() {
    this.saveTicket.emit(this.ticketId);
  }

  protected onCallNotesChange(event) {
    this.storageService.setDescription(event.srcElement.value.trim(), this.ticketId);
  }

  protected loadQuickComment(comment: string) {
    this.loggerService.logger.logTrace('Zendesk - Activity : START : Load Quick Comments. Input : ' + comment);
    try {
      const descriptionToSet = this.quickCommentList[comment];
      const currentDescription = this.storageService.ticketList[this.ticketId].Description;
      if (!currentDescription) {
        this.storageService.setDescription(descriptionToSet, this.ticketId);
      } else {
        this.storageService.setDescription(currentDescription + '\n' + descriptionToSet, this.ticketId);
      }
    } catch (error) {
      this.loggerService.logger.logError('Zendesk - Activity : ERROR : Load Quick Comments. Input : '
      + comment + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Zendesk - Activity : END : Load Quick Comments. Input : ' + comment);
  }
}
